import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Navbar from "./Navbar";
// eslint-disable-next-line
import TextGallery from "../components/Reviews";
import jobDescriptions, { jobs } from "./jobDescriptions";
import RegistrationClosed from "./RegistrationClosed";



const JOBS_PER_PAGE = 6;

export default function JobPosts() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRegistrationClosed, setShowRegistrationClosed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationTerm, setLocationTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("date"); // Sort option state
  const { jobTitle, uid } = useParams();
  const navigate = useNavigate();

  const gradientColors = [
    "#FDE8CD", // Light Orange
    "#E0F8E0", // Light Green
    "#F3E5F5", // Light Purple
    "#E0F7FA", // Light Blue
    "#FCE4EC", // Light Pink
    "#F5F5F5", // Light Grey
 
  ];

 

const getGradientBackground = (index) => {
  const color = shuffledColors[index % shuffledColors.length];
  return `linear-gradient(to bottom, ${color} 0%, ${color} calc(100% - 50px), transparent calc(100% - 50px))`;
};
  const shuffleArray = (array) => {
    let shuffledArray = array.slice(); // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  const shuffledColors = shuffleArray(gradientColors);
  
  useEffect(() => {
    if (jobTitle && uid) {
      const job = jobs.find((job) => job.id === parseInt(uid));
      setSelectedJob(job);
    } else {
      setSelectedJob(null);
    }
  }, [jobTitle, uid]);

  const handleGoBack = () => {
    navigate("/JobPostings");
  };

  const handleDetailsClick = (job) => {
    navigate(
      `/jobposting/${job.title.toLowerCase().replace(/\s+/g, "-")}/${job.id}`
    );
  };

  const handleApply = (job) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowRegistrationClosed(true);
    }, 2000);
  };

  // Sort jobs by selected option
  const sortedJobs = [...jobs].sort((a, b) => {
    if (sortOption === "date") {
      return new Date(b.postedDate) - new Date(a.postedDate);
    } else if (sortOption === "salary") {
      // Extract salary numbers and compare them
      const salaryA = parseInt(a.salary.match(/\d+/g).join(""));
      const salaryB = parseInt(b.salary.match(/\d+/g).join(""));
      return salaryB - salaryA;
    }
    return 0;
  });

  // Filter jobs based on searchTerm and locationTerm
  const filteredJobs = sortedJobs.filter(
    (job) =>
      (job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (locationTerm === "" ||
        job.location.toLowerCase().includes(locationTerm.toLowerCase()))
  );

  const totalPages = Math.ceil(filteredJobs.length / JOBS_PER_PAGE);
  const paginatedJobs = filteredJobs.slice(
    (currentPage - 1) * JOBS_PER_PAGE,
    currentPage * JOBS_PER_PAGE
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!selectedJob) {
    return (
      <>
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-3xl lg:text-4xl xl:text-5xl font-semibold mb-8 text-gray-400">
              Job Postings
            </h1>
          </div>

         
         {/* Search Section */}
<div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-8">
  <div className="flex flex-col sm:flex-row items-center w-full lg:w-3/4 xl:w-2/3 space-y-4 sm:space-y-0">
    {/* Job Title Search */}
    <div className="relative w-full sm:w-1/2 sm:mr-2">
      <input
        type="text"
        className="block w-full custom-input"
        placeholder="Search by job title"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
      />
      {searchTerm && (
        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 "
          onClick={() => {
            setSearchTerm("");
            setCurrentPage(1);
          }}
        >
          ✕
        </button>
      )}
    </div>

    {/* Location Dropdown */}
    <div className="relative w-full sm:w-1/2">
      <select
        name="area"
        className="mt-1 block w-full border border-gray-300 rounded-md mt-1 block w-full border border-gray-300 rounded-md p-4"
        style={{ padding: "1.2rem" }}
        value={locationTerm}
        onChange={(e) => {
          setLocationTerm(e.target.value);
          setCurrentPage(1);
        }}
      >
        <option value="">All Locations</option>
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="NB">New Brunswick</option>
        <option value="ON">Ontario</option>
        <option value="QC">Quebec</option>
        {/* Add other locations */}
      </select>
    </div>
  </div>

  {/* Sort By Dropdown */}
  <div className="relative w-full lg:w-auto mt-4 lg:mt-0">
    <select
      className="block  lg:w-auto border border-gray-300 rounded-md p-2"
      value={sortOption}
      onChange={(e) => setSortOption(e.target.value)}
    >
      <option value="date">Most Recent</option>
      <option value="salary">Sort by Salary</option>
    </select>
  </div>
</div>


          {/* Pagination Info */}
          <div className="flex justify-end mb-4">
            <p className="text-gray-600">
              Page {currentPage} of {totalPages}
            </p>
          </div>

          {/* Job Cards Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
  {paginatedJobs.map((job, index) => (
    <div
      key={job.id}
      className="relative border border-gray-300 rounded-2xl shadow-sm overflow-hidden"
      style={{ height: "350px" }} // Adjust height as needed
    >
      {/* Background Gradient covering full container except for salary section */}
      <div
        className="absolute inset-0 rounded-2xl overflow-hidden"
        style={{
          background: getGradientBackground(index),
        }}
      ></div>

      <div className="relative p-6 flex flex-col h-full">
        {/* Date Container */}
        <div
          className="absolute top-4 left-4 bg-white text-gray-500 rounded-lg px-3 py-1 shadow-sm"
          style={{
            borderRadius: "22px",
            
          }}
        >
          <p>{job.postedDate}</p>
        </div>

        {/* Job Title */}
        <div className="mt-16 text-2xl font-semibold text-gray-800">
          {job.title}
        </div>

        {/* Keywords */}
        <div className="mt-4 mb-4 flex flex-wrap gap-2">
          {job.keyword1 && (
            <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
              {job.keyword1}
            </span>
          )}
          {job.keyword2 && (
            <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
              {job.keyword2}
            </span>
          )}
          {job.keyword3 && (
            <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
              {job.keyword3}
            </span>
          )}
          {job.keyword4 && (
            <span className="px-3 py-1 bg-transparent rounded-full border border-gray-400 text-sm text-black">
              {job.keyword4}
            </span>
          )}
        </div>

        {/* Salary, Location, and Details Button */}
        <div
          className="absolute bottom-3 left-0 right-0 px-4"
          style={{
            backgroundColor: "white",
            borderRadius: "16px", // Same border-radius for consistency
            height: "70px", // Adjust as needed padding: "10px 0", // Add padding to push content down
            paddingTop: "15px",
          }}
        >
          <div className="flex justify-between items-center space-x-4">
            <div>
              <p className="text-black">{job.salary}</p>
              <p className="text-black">{job.location}</p>
            </div>

            {/* Details Button */}
            <button
              onClick={() => handleDetailsClick(job)}
              className="button-24"
            >
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

          {/* Pagination Control */}
          <div className="flex justify-center mt-8">
  <nav className="inline-flex items-center border rounded-md">
    {/* Previous button */}
    <button
      className={`px-3 py-2 rounded-l-md ${
        currentPage === 1
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-gray-200 text-gray-600 hover:bg-gray-300"
      }`}
      onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      «
    </button>

    {/* Dynamic page buttons */}
    {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
      let page = index + Math.max(1, Math.min(currentPage - 2, totalPages - 4)); // Adjust range dynamically
      if (page > totalPages) return null;

      return (
        <button
          key={page}
          className={`px-3 py-2 ${
            currentPage === page
              ? "bg-gray-800 text-white font-bold"
              : "bg-gray-200 text-gray-600 hover:bg-gray-300"
          }`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      );
    })}

    {/* Ellipsis if necessary */}
    {currentPage < totalPages - 2 && totalPages > 5 && (
      <span className="px-3 py-2 text-gray-500">...</span>
    )}

    {/* Last page button */}
    {totalPages > 5 && ![...Array(5).keys()].map(i => i + Math.max(1, Math.min(currentPage - 2, totalPages - 4))).includes(totalPages) && (
      <button
        className={`px-3 py-2 ${
          currentPage === totalPages
            ? "bg-gray-800 text-white font-bold"
            : "bg-gray-200 text-gray-600 hover:bg-gray-300"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    )}

    {/* Next button */}
    <button
      className={`px-3 py-2 rounded-r-md ${
        currentPage === totalPages
          ? "bg-gray-300 text-gray-500 cursor-not-allowed"
          : "bg-gray-200 text-gray-600 hover:bg-gray-300"
      }`}
      onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      »
    </button>
  </nav>
</div>



        </div>
      </>
    );
  }

  // Job Details Page
  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <button className="button-24" onClick={handleGoBack}>
          Go Back
        </button>
        <div className="mt-4 p-4 border border-gray-200 rounded shadow-lg">
          <h2 className="text-xl font-semibold">{selectedJob.title}</h2>
          <p className="text-gray-600">Location: {selectedJob.location}</p>
          <p className="text-gray-600">Salary: {selectedJob.salary}</p>
          <div
            className="mb-8 mt-8"
            dangerouslySetInnerHTML={{
              __html: jobDescriptions[selectedJob.descriptionKey],
            }}
          />
          {!isLoading && !showRegistrationClosed && (
            <button
              className="button-23"
              onClick={() => handleApply(selectedJob)}
            >
              Apply
            </button>
          )}
          {isLoading && (
            <div className="flex justify-center items-center mt-4">
              <svg
                className="animate-spin h-8 w-8 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5V12l4.5-4.5M12 12l-4.5-4.5M12 12v7.5m0-7.5L7.5 12"
                />
              </svg>
            </div>
          )}
          {!isLoading && showRegistrationClosed && (
            <div className="mt-8 border-t-4 border-gray-100 pt-4">
              <RegistrationClosed />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
